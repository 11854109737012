 
  <div class="sb-nav-fixed" [class.sb-sidenav-toggled]="menuOpened">
    <nav class="sb-topnav navbar navbar-expand navbar-dark top-bar-bg">
      <a class="navbar-brand" href="/">{{'muqawil' | translate}}</a>
      <button class="btn btn-link btn-sm order-1 order-lg-0" (click)="menuOpened = !menuOpened" id="sidebarToggle"
        href="#">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </button>
      <!-- Navbar Search-->
      <ul class="nav nav-pills">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle float-right text-white" data-toggle="dropdown" href="#" role="button"
            aria-haspopup="true" aria-expanded="false">{{'language' | translate}}</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" (click)="useLanguage('en')" href="#">En</a>
            <a class="dropdown-item" (click)="useLanguage('ar')" href="#">Ar</a>
          </div>
        </li>
      </ul>
      <form class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
        <div class="input-group">
          <input class="form-control" type="text" placeholder="{{'searchfor' | translate}}" aria-label="Search"
            aria-describedby="basic-addon2" />
          <div class="input-group-append">
            <button class="btn btn-primary" type="button">
              <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
          </div>
        </div>
      </form>
      <!-- Navbar-->
      <ul class="navbar-nav ml-auto ml-md-0">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="userDropdown" href="#" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <fa-icon [icon]="['fas', 'user']"></fa-icon>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
            <a class="dropdown-item" href="#">{{'setting' | translate}}</a>
            <a class="dropdown-item" href="#">{{'activitylog' |translate}}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="login.html">{{'logout' | translate}}</a>
          </div>
        </li>
      </ul>
    </nav>
    <div id="layoutSidenav">
      <div id="layoutSidenav_nav">
        <nav class="sb-sidenav accordion img" id="sidebar" style="background-image: url(../assets/images/nav-bg.jpg);">
         
            <div class="pad">
            <span *ngFor="let mainItem of my_menu; let i = index">

        
              <ul class="list-unstyled components mb-5" *ngFor="let Item of mainItem.menu">
                <li [class.active]='current === i' (click) = "current = i">
                  <a  data-toggle="collapse" href="#{{Item.id}}" role="button" aria-expanded="false" aria-controls="collapseExample" class="ripple"><fa-icon [icon]="Item.icon" class="mr-2"></fa-icon>{{Item.main |translate}}</a>
                  <ul [class.collapse]='current != i' class="padl mt-3">
                    <li *ngFor="let subItem of Item.submenu" class="collapse" id="collapseExample">
                    <a class="ripple" href="" routerLink="subItem.route" [title]="subItem.route"><fa-icon [icon]="subItem.icon" class="mr-3"></fa-icon>{{ subItem.sub |translate }}</a></li>
                  </ul>
                </li>
                
              </ul>
     </span>
               
    
           
    
            </div>
          
          <div class="sb-sidenav-footer">
            <div class="small">{{'loginas'|translate}}:</div>
           {{'muqawil' | translate}}
          </div>
        </nav>
      </div>
      <div id="layoutSidenav_content">
        <main>
          <div class="container-fluid" >
            
            <div id="myCarousel" class="carousel slide mt-3" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
                <li data-target="#myCarousel" data-slide-to="2"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../assets/images/bg1.jpg" width="100%">
                  <div class="container">
                    <div class="carousel-caption text-center">
                      <h1>{{'heading' | translate}}</h1>
                      <p>{{'headingtext1' | translate}}</p>
                      <p><a class="btn btn-lg btn-primary" href="#" role="button">{{'signup' | translate}}</a></p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                <img src="../assets/images/bg2.jpg" width="100%">
                  <div class="container">
                    <div class="carousel-caption">
                      <h1>{{'heading2' | translate}}</h1>
                      <p>{{'headingtext2' | translate}}</p>
                      <p><a class="btn btn-lg btn-primary" href="#" role="button">{{'learnmore' |translate}}</a></p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <img src="../assets/images/bg3.jpeg" width="100%">
                  <div class="container">
                    <div class="carousel-caption text-right">
                    
                      <h1>{{'heading3' | translate}}</h1>
                      <p>{{'headingtext3' | translate}}</p>
                        <p><a class="btn btn-lg btn-primary" href="#" role="button">{{'learnmore'|translate}}</a></p>
                    </div>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
            <ol class="breadcrumb mb-4 mt-4">
              <li class="breadcrumb-item active">{{'home' | translate}}</li>
            </ol>
            
            <div class="row">
              <div class="col-xl-3 col-md-6">
                <div class="card bg-primary text-white mb-4">
                  <div class="card-body">{{'primarycard' | translate}}</div>
                  <div class="card-footer d-flex align-items-center justify-content-between">
                    <a class="small text-white stretched-link" href="#">{{'viewdetails' | translate}}</a>
                    <div class="small text-white">
                      <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-md-6">
                <div class="card bg-warning text-white mb-4">
                  <div class="card-body">{{'warningcard' | translate}}</div>
                  <div class="card-footer d-flex align-items-center justify-content-between">
                    <a class="small text-white stretched-link" href="#">{{'viewdetails' | translate}}</a>
                    <div class="small text-white">
                      <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-md-6">
                <div class="card bg-success text-white mb-4">
                  <div class="card-body">{{'successcard' | translate}}</div>
                  <div class="card-footer d-flex align-items-center justify-content-between">
                    <a class="small text-white stretched-link" href="#">{{'viewdetails' | translate}}</a>
                    <div class="small text-white">
                      <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-md-6">
                <div class="card bg-danger text-white mb-4">
                  <div class="card-body">{{'dangercard' | translate}}</div>
                  <div class="card-footer d-flex align-items-center justify-content-between">
                    <a class="small text-white stretched-link" href="#">{{'viewdetails' | translate}}</a>
                    <div class="small text-white">
                      <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          
            <div class="container marketing">
            <div class="row">
              <div class="col-lg-4">
                <fa-icon class="bd-placeholder-img rounded-circle fa-5x text-success" width="140" height="140" [icon]="['fas', 'broom']"></fa-icon>
                
                <h2>{{'heading' | translate}}</h2>
                <p>{{'headingtext1' | translate}}</p>
                <p><a class="btn btn-secondary" href="#" role="button">{{'viewdetails' | translate}} &raquo;</a></p>
              </div><!-- /.col-lg-4 -->
              <div class="col-lg-4">
                <fa-icon class="bd-placeholder-img rounded-circle fa-5x text-success" width="140" height="140" [icon]="['fas', 'broadcast-tower']"></fa-icon>
                <h2>{{'heading' | translate}}</h2>
                <p>{{'headingtext1' | translate}}</p>
                <p><a class="btn btn-secondary" href="#" role="button">{{'viewdetails' | translate}} &raquo;</a></p>
              </div><!-- /.col-lg-4 -->
              <div class="col-lg-4">
                <fa-icon class="bd-placeholder-img rounded-circle fa-5x text-success" width="140" height="140" [icon]="['fas', 'bullhorn']"></fa-icon>
                <h2>{{'heading' | translate}}</h2>
                <p>{{'headingtext1' | translate}}</p>
                <p><a class="btn btn-secondary" href="#" role="button">{{'viewdetails' | translate}} &raquo;</a></p>
              </div><!-- /.col-lg-4 -->
            </div><!-- /.row -->
          </div>
          <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading">{{'featureheading1' |translate}}</h2>
        <p class="lead">{{'featuretext1' |translate}}</p>
      </div>
      <div class="col-md-5 order-md-1">
        <img src="../assets/images/bg4.jpeg" width="100%">
      </div>
    </div>

    <hr class="featurette-divider">

    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading">{{'featureheading2' |translate}}</h2>
        <p class="lead">{{'featuretext1' |translate}}</p>
      </div>
      <div class="col-md-5">
       <img src="../assets/images/bg5.jpg" width="100%">
      </div>
    </div>

    <hr class="featurette-divider">
            <div class="card mb-4">
              <div class="card-header">
                <fa-icon [icon]="['fas', 'table']"></fa-icon>
                {{'datatableexample' | translate}}
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                      <tr>
                        <th>{{'name' | translate}}</th>
                        <th>{{'position' | translate}}</th>
                        <th>{{'office' | translate}}</th>
                        <th>{{'age' | translate}}</th>
                        <th>{{'startdate' | translate}}</th>
                        <th>{{'salary' | translate}}</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>{{'name' | translate}}</th>
                        <th>{{'position' | translate}}</th>
                        <th>{{'office' | translate}}</th>
                        <th>{{'age' | translate}}</th>
                        <th>{{'startdate' | translate}}</th>
                        <th>{{'salary' | translate}}</th>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr>
                        <td>Tiger Nixon</td>
                        <td>System Architect</td>
                        <td>Edinburgh</td>
                        <td>61</td>
                        <td>2011/04/25</td>
                        <td>$320,800</td>
                      </tr>
                      <tr>
                        <td>Garrett Winters</td>
                        <td>Accountant</td>
                        <td>Tokyo</td>
                        <td>63</td>
                        <td>2011/07/25</td>
                        <td>$170,750</td>
                      </tr>
                      <tr>
                        <td>Ashton Cox</td>
                        <td>Junior Technical Author</td>
                        <td>San Francisco</td>
                        <td>66</td>
                        <td>2009/01/12</td>
                        <td>$86,000</td>
                      </tr>
                      <tr>
                        <td>Cedric Kelly</td>
                        <td>Senior Javascript Developer</td>
                        <td>Edinburgh</td>
                        <td>22</td>
                        <td>2012/03/29</td>
                        <td>$433,060</td>
                      </tr>
                      <tr>
                        <td>Airi Satou</td>
                        <td>Accountant</td>
                        <td>Tokyo</td>
                        <td>33</td>
                        <td>2008/11/28</td>
                        <td>$162,700</td>
                      </tr>
                      <tr>
                        <td>Brielle Williamson</td>
                        <td>Integration Specialist</td>
                        <td>New York</td>
                        <td>61</td>
                        <td>2012/12/02</td>
                        <td>$372,000</td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer class="py-4 bg-light mt-auto">
          <div class="container-fluid">
            <div class="d-flex align-items-center justify-content-between small">
              <div class="text-muted">{{'copyright' | translate}} &copy;  2020</div>
              <div>
                <a href="#">{{'privacy' | translate}}</a>
                &middot;
                <a href="#">{{'terms' | translate }}</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
 